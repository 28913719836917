html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
body {
  line-height: 1;
  color: #000;
  background: #fff;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  vertical-align: middle;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}
a img {
  border: none;
}
@font-face {
  font-family: 'icons';
  src: url("/fonts/icons.eot?b2jyux");
  src: url("/fonts/icons.eot?b2jyux#iefix") format('embedded-opentype'), url("/fonts/icons.ttf?b2jyux") format('truetype'), url("/fonts/icons.woff?b2jyux") format('woff'), url("/fonts/icons.svg?b2jyux#icons") format('svg');
  font-weight: normal;
  font-style: normal;
}
i {
/* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
/* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-100:before {
  content: "\1f4af";
}
.icon-boom:before {
  content: "\1f4a5";
}
.icon-door:before {
  content: "\e902";
}
.icon-hello:before {
  content: "\1f44b";
}
.icon-newsletter:before {
  content: "\1f4f0";
}
.icon-email:before {
  content: "\1f4f0";
}
.icon-pdf:before {
  content: "\e905";
}
.icon-read:before {
  content: "\2714";
}
.icon-spark:before {
  content: "\e907";
}
.icon-tape:before {
  content: "\1f3a7";
}
.icon-mouse-one:before {
  content: "\e909";
}
.icon-mouse-two:before {
  content: "\e90a";
}
html {
  height: 100%;
}
*:focus {
  outline: none !important;
}
body {
  background-color: #000;
  color: #fff;
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
  padding: 0;
  margin: 0;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  line-height: 1.2;
  letter-spacing: 1px;
}
@media all and (max-width: 632px) {
  body {
    line-height: 1.4;
    letter-spacing: 0.5px;
  }
}
strong {
  font-weight: bold;
}
em {
  font-weight: bold;
  font-style: italic;
}
a {
  color: inherit;
  text-decoration: none;
  border-bottom: 2px dotted #fff;
  line-height: 1;
  user-select: none;
}
a[target='_blank'] {
  border-bottom: 2px solid #fff;
}
@media all and (min-width: 632px) {
  a:hover {
    border-bottom-color: transparent;
  }
}
a.hiddenLink {
  border-bottom-color: transparent !important;
}
a.active {
  color: #fff;
  border-bottom-color: #fff !important;
}
p,
.blockWrapper {
  padding-bottom: 0;
}
p:last-child,
.blockWrapper:last-child {
  padding-bottom: 0;
  margin-bottom: -77px;
}
.block,
.textBlock,
.imageBlock,
.imageBlock.medium,
.videoBlock,
.tableBlock {
  padding-bottom: 14px;
}
.textBlock,
.imageBlock.medium,
.tableBlock {
  width: 600px;
  margin: 0 auto;
}
@media all and (max-width: 632px) {
  .textBlock,
  .imageBlock.medium,
  .tableBlock {
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    font-size: 16px;
  }
}
.imageBlock {
  box-sizing: border-box;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding-left: 48px;
  padding-right: 48px;
  position: relative;
}
@media all and (max-width: 632px) {
  .imageBlock {
    padding-left: 0;
    padding-right: 0;
  }
}
.imageBlock .imageWrapper {
  width: 100%;
  max-width: 1200px;
  margin: 3px auto;
}
.imageBlock .imageWrapper img {
  display: block;
}
.imageBlock.medium {
  padding-left: 0;
  padding-right: 0;
}
.imageBlock.transparent .imageWrapper {
  background-color: transparent !important;
}
.imageBlock.macWindow {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-top: -2%;
  margin-bottom: -2%;
}
.imageBlock.macWindow .imageWrapper {
  background-color: transparent !important;
}
.imageBlock.macWindow .imageCaption {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  position: absolute;
  top: 89%;
  left: 4%;
}
.imageWrapper {
  position: relative;
  height: 0;
  background-color: #333;
}
.imageWrapper img {
  width: 100%;
  position: absolute;
}
.imageCaption {
  font-size: 60%;
  opacity: 0.5;
  margin: 0 auto 3px;
}
@media all and (max-width: 632px) {
  .imageCaption {
    margin-bottom: 0;
    box-sizing: border-box;
    padding: 0 16px;
  }
}
.videoBlock {
  width: 600px;
  margin: 0 auto;
}
@media all and (max-width: 632px) {
  .videoBlock {
    width: 100%;
  }
}
.videoBlock .videoBlockContainer {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  background-color: #333;
}
.videoBlock .videoBlockContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.tableBlock table td {
  font-size: 73%;
  line-height: 1.4;
  vertical-align: top;
  padding-bottom: 3px;
  padding-left: 15px;
}
.tableBlock table td:first-child {
  padding-left: 0px;
}
.slidesBlock {
  width: 100%;
  padding-bottom: 0;
}
#line {
  height: 2px;
  transform: translateY(-1px);
  width: 100%;
  position: fixed;
  background-color: #f00;
  top: 50%;
  left: 0;
  opacity: 0.8;
  display: none;
}
.MainBlock .block {
  transition: color 0.2s ease-in-out;
}
.MainBlock .block a {
  transition: border-bottom-color 0.2s ease-in-out;
}
.MainBlock .block svg {
  transition: opacity 0.2s ease-in-out;
}
.MainBlock .inactive {
  color: rgba(255,255,255,0.3);
}
.MainBlock .inactive a {
  border-bottom-color: rgba(255,255,255,0.3);
}
.MainBlock .inactive svg {
  opacity: 0.3;
}
.MainBlocks svg path,
.MainBlocks svg polygon {
  fill: #fff;
}
.MainPage {
  padding-top: calc(50vh - 87px);
  padding-bottom: calc(50vh - 87px);
}
.ScrollHighlight {
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}
.ScrollHighlight--paddings {
  position: relative;
  top: 50vh;
  transition: top 0.5s ease-in-out;
  padding-bottom: 50vh;
  box-sizing: border-box;
}
.ScrollHighlight--element {
  transition: color 0.2s ease-in-out;
}
.ScrollHighlight--element a {
  transition: border 0.2s ease-in-out;
}
.ScrollHighlight--element svg {
  transition: opacity 0.2s ease-in-out;
}
.ScrollHighlight--element.inactive {
  color: rgba(255,255,255,0.3);
}
.ScrollHighlight--element.inactive a {
  border-bottom-color: rgba(255,255,255,0.3);
}
.ScrollHighlight--element.inactive svg {
  opacity: 0.3;
}
.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
/* Fix of Webkit flickering */
  z-index: 1;
}
.swiper-container-no-flexbox .swiper-slide {
  float: left;
}
.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
}
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
.swiper-wp8-horizontal {
  touch-action: pan-y;
}
.swiper-wp8-vertical {
  touch-action: pan-x;
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto;
}
.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto;
}
.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms;
  transform: translate3d(0, 0, 0);
  z-index: 1000;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000 !important;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
  margin: 2px;
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: #000;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff;
}
.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000;
}
.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px;
}
.swiper-pagination-progress {
  background: rgba(0,0,0,0.25);
  position: absolute;
}
.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}
.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}
.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255,255,255,0.5);
}
.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff;
}
.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000;
}
.swiper-container-3d {
  perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.5)), to(rgba(0,0,0,0)));
/* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(right, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Firefox 3.6-15 */
  background-image: -o-linear-gradient(right, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Opera 11.10-12.00 */
  background-image: linear-gradient(to left, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0,0,0,0.5)), to(rgba(0,0,0,0)));
/* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Firefox 3.6-15 */
  background-image: -o-linear-gradient(left, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Opera 11.10-12.00 */
  background-image: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0.5)), to(rgba(0,0,0,0)));
/* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(bottom, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(bottom, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Firefox 3.6-15 */
  background-image: -o-linear-gradient(bottom, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Opera 11.10-12.00 */
  background-image: linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,0.5)), to(rgba(0,0,0,0)));
/* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0));
/* Firefox 16+, IE10, Opera 12.50+ */
}
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible;
}
.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  backface-visibility: hidden;
  z-index: 1;
}
.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  backface-visibility: hidden;
}
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
  transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  filter: blur(50px);
  z-index: 0;
}
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  background: rgba(0,0,0,0.1);
}
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}
.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}
.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0,0,0,0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}
.swiper-scrollbar-cursor-drag {
  cursor: move;
}
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}
.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
@-moz-keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
.WorkPage {
  background-color: rgba(255,255,255,0.9);
  width: 100%;
  margin-bottom: 14px;
  padding-top: 14px;
  box-sizing: border-box;
}
.WorkPage .block {
  color: #000 !important;
}
.WorkPage .block svg {
  opacity: 1 !important;
}
.WorkPage .block path,
.WorkPage .block polygon {
  fill: #000 !important;
}
.WorkPage a {
  border-bottom-color: #000 !important;
}
.WorkPage a:hover {
  border-bottom-color: transparent;
}
.WorkPage video {
  width: 100%;
  height: auto;
}
.WorkPage .close {
  color: #000;
  padding: 3px 17px 17px;
  float: right;
  transition: opacity 0.3s ease-in-out;
  border-bottom: none;
  font-size: 20px;
}
.WorkPage .close:hover {
  opacity: 0.3;
}
.clear {
  clear: both;
}
